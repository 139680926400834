import React, { Component } from 'react';
import Submenu from '../Submenu/Submenu';
import * as styles from './SubpageNavbar.module.scss';
import logoImage from '../../assets/logo.png';
import menuIco from '../../assets/menu.svg';
import {isMobile} from 'react-device-detect';
// import { animateScroll as scroll } from "react-scroll";

class SubpageNavbar extends Component {
  constructor(props) {
    super(props);
    if (!isMobile) {
      this.state = {isMenuVisible: true};
    } else {
      this.state = {isMenuVisible: false};
    }
  }
  
    openMenu = () => {
      let isVisible = this.state.isMenuVisible;
      if (isVisible) {
        this.setState({
          isMenuVisible: false,
        });
      } else {
        this.setState({
          isMenuVisible: true,
        });
      }
    }
  
    render() {
        const { isMenuVisible } = this.state;

        return (
          <div className={styles.wrapper}>
            <div className={styles.container}>
                {/* <NavLink
                    to="/"
                > */}
                    <img
                        className={styles.logo}
                        src={logoImage}
                        alt="Leximo"
                    />
                {/* </NavLink> */}
                <nav>
                    <button
                        className={styles.menuBtn}
                        onClick={this.openMenu}
                    >
                    <img src={menuIco} alt="Menu" />
                    </button>
                    { isMenuVisible &&
                        <Submenu />
                    }
                </nav>
            </div>
          </div>
        )
    }
};

export default SubpageNavbar;