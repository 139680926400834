import React, { Component } from 'react';
import * as styles from './Submenu.module.scss';
import { LocMenu, Loc } from '../frontlocdata';
// import './Menu.css';

class Submenu extends Component {
    render() {

    var otherLangSubDomain = "";
    if(Loc === "pl")
    {
      otherLangSubDomain = "en.";
    }

        return (
          <>
            <ul className={styles.wrapper}>
              <li>
                <a href="/#team">
                    {LocMenu["our-team"][Loc]}
                  </a>
              </li>
              <li>
                <a href="/#services">
                  {LocMenu["our-services"][Loc]}
                </a>
              </li>
              <li>
                <a href="/#credentials">
                  {LocMenu["credentials"][Loc]}
                </a>
              </li>
              <li>
                <a href="/#quote">
                  {LocMenu["quote"][Loc]}
                </a>
              </li>
              {/* <li>
                <a href="/polityka-prywatnosci" className={styles.active}>
                  Polityka prywatności
                </a>
              </li> */}
              <li>
                <a href="/#contact">
                  {LocMenu["contact"][Loc]}
                </a>
              </li>
            </ul>
            <ul className={styles.language}>
              <li>
                <a href={"https://"+otherLangSubDomain+"leximo.pl"}>
                  {LocMenu["other-lang"][Loc]}
                </a>
              </li>
            </ul>
          </>
        )
    }
};

export default Submenu;