import React from 'react';
import DynamicComponent from '../DynamicComponent/DynamicComponent';
import Titles from '../Titles/Titles';
import * as styles from './PrivacyPolicy.module.scss';
import { LocPolicy, Loc } from '../frontlocdata';

class PrivacyPolicy extends DynamicComponent {

  async componentDidMount() {
    this.fetchState('privacy-policy');
    this.fetchState('cookies-policy');
  }
    render() {
        return (
          <div
            className={styles.container}
          >
            <div
              data-aos="fade-right"
              className={styles.wrapper}
            >
              <Titles
                title=""
                subtitle={LocPolicy["privacy-policy"][Loc]}
              />
              <div className={styles.text} id="privacy-policy" dangerouslySetInnerHTML={{__html: this.state.fetched['privacy-policy']}}></div>
            </div>
            <div
              data-aos="fade-left"
              className={styles.wrapper}
            >
              <Titles
                  title=""
                  subtitle={LocPolicy["cookies-policy"][Loc]}
              />
              <div className={styles.text} id="cookies-policy" dangerouslySetInnerHTML={{__html: this.state.fetched['cookies-policy']}}></div>
            </div>
          </div>
        )
    }
};

export default PrivacyPolicy;